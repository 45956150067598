import React,  { useState } from "react";
import Header from "../components/header/header";
import resumeStyles from "./resume.module.css";
import Helmet from "react-helmet";

export default function Resume(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="index">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Resume</title>
      </Helmet>
      <Header page='resume' open={open} setOpen={setOpen}/>
      <div className='content'>
      <div className={resumeStyles.resume}>
       <a href="JuliaEdwardsResumeFall2021.pdf">
        <div className={resumeStyles.container}>
        <img src={'/resumeThumb.png'} alt='resume' className={resumeStyles.thumb}></img>
        <div className={resumeStyles.overlay}>
          <div className={resumeStyles.text}>Resume</div>
        </div>
        </div>
        </a>
        <br></br>
        <a href='JuliaEdwardsedresume2020.pdf' download> Download</a>
      </div>
      </div>
    </div>
  )
}
